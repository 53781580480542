import FqApi from '@/services/fq-api'

export default {
  // async getCompanyMonthlySetting() {
  //   const url = 'companymonthlysettings/getcompanymonthlysetting'
  //   const result = await FqApi.get(url)
  //   return result.data
  // },
  async getCompanyMonthlySettingByFY(fiscalYear) {
    const url = `companymonthlysettings/getcompanymonthlysettingbyfy/${fiscalYear}`
    const result = await FqApi.get(url)
    // console.log(JSON.stringify(result.data))
    return result.data
  },
  async getFiscalYearList() {
    const url = 'companymonthlysettings/getfiscalyearlist'
    const result = await FqApi.get(url)
    return result.data
  },
  async getAllFiscalYear() {
    const url = 'companymonthlysettings/getallfiscalyear'
    const result = await FqApi.get(url)
    return result.data
  },
  async checkExists(year, month) {
    const url = `companymonthlysettings/checkexists/${year}/${month}`
    const result = await FqApi.get(url)
    return result.data
  },
  async postEntity(entity) {
    return FqApi.post('/companymonthlysettings', entity)
  },
  async putEntity(fiscalYear, entity) {
    return FqApi.put(`/companymonthlysettings?fiscalyear=${fiscalYear}`, entity)
  }
}
