<template>
  <div>
    <app-detail-header :show-delete="false"
      :show-new="false"
      :show-save="false"
      :show-cancel="false"
      :title="jcniObj[selectedJcni]"
      :show-print="true"
      @appDetailHeaderButtonClicked="handlePrint" />

    <div class="tile is-ancestor is-parent">
      <div class="tile is-vertical is-parent">
        <jcni-menu-bar :selected-jcni="selectedJcni"
          :is-loading="isLoading"
          :data="jcniRecords"
          :include-gst="includeGst"
          @change="handleJCNIEvent" />

        <div class="tile is-child box">
          <bulma-table class="is-striped is-narrow is-fullwidth is-hoverable"
            :columns="columns"
            :data="sortedData"
            :total-rows="jcniRecords.length"
            :page-size="jcniRecords.length"
            :is-loading="isLoading"
            :sort-column="sortColumn"
            :sort-order="sortOrder"
            :show-pagination="false"
            @sort="handleSort"
            :has-sticky-header="true"
            sticky-header-top="10.25rem"
            ref="jcnitable">
            <tr v-for="(job, index) in sortedData"
              :key="index"
              :class="{ 'is-selected' : selectedRow === index }"
              @click="highlightSelected(index, $event)">
              <td class="">
                <a @click="handleQuoteClick(job.quoteId)">{{ job.quoteNo }}</a>
              </td>
              <td class="has-text-left">{{ job.jobStage }}</td>
              <td class="has-text-left">{{ job.fullName }}</td>
              <td class="has-text-centered">{{ job.rego }}</td>
              <td class="has-text-left">{{ job.insurer }}</td>
              <td class="has-text-centered">{{ job.daysElapsed }}</td>
              <td class="has-text-centered">
                {{ $filters.formatDateTimeLocale(job.jobEnd, $userInfo.locale) }}
              </td>
              <td class="has-text-right">
                {{ $filters.formatCurrency(job.jobTotal, $userInfo.locale) }}
              </td>
              <td class="has-text-right">
                {{ $filters.formatCurrency(job.debtorInvoice, $userInfo.locale) }}
              </td>
              <td class="has-text-right">
                {{ $filters.formatCurrency(job.excessInvoice, $userInfo.locale) }}
              </td>
              <td class="has-text-right">
                {{ $filters.formatCurrency(job.totalNotInvoiced, $userInfo.locale) }}
              </td>
              <td class="has-text-centered col-1">
                <a @mouseover="getNotesContent(job.isNoteExists, job.notes)"
                  @click="notesClick(job.quoteId, job.quoteNo)"
                  class="button is-dark is-small is-inverted">
                  <span class="icon is-medium"
                    v-tippy="{ enabled: showNoteContent, placement: 'right-start', arrow: false, allowHTML: true }"
                    :content="notesContent">
                    <i class="mdi mdi-24px"
                      :class="[job.isNoteExists ? 'mdi-note-edit has-text-warning': 'mdi-note-edit-outline has-text-grey']" />
                  </span>
                </a>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </div>
      </div>
    </div>
    <div ref="notesList"
      class="is-hidden">
      <ul style="list-style-type: disc; padding-left: 1rem;">
        <li v-for="(note, index) in notes"
          :key="index">
          <span class="is-size-6">{{ note.remarks }}</span>
          <div class="is-flex is-align-items-center is-size-7">
            <user-profile-icon v-if="note.firstName || note.displayName"
              :is-text-profile="true"
              :first-name="note.firstName"
              :last-name="note.lastName"
              :display-name="note.displayName"
              :width="20"
              :height="20"
              class="pr-1" />
            <span class="help is-italic has-text-grey">{{ $filters.formatDateTimeLocale(note.remarkDateTime, $userInfo.locale) }}</span>
          </div>
        </li>
      </ul>
    </div>
    <note-modal v-if="isNoteModalActive"
      :active.sync="isNoteModalActive"
      :quote-id="selectedQuoteId"
      :job-no="selectedJobNo"
      :remark-type="remarkType"
      :remark-desc="remarkDesc"
      @close="closeNoteModal" />
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import JcniService from './JcniService'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import JcniMenuBar from './JcniMenuBar'
import cloneDeep from 'lodash.clonedeep'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import StoreMixin from './storeMixin'
import { Emailer } from '@/classes'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import NoteModal from '@/components/NoteModal/NoteModal'
// import NoteValidation from '@/components/NoteModal/NoteValidation'
import { RemarkTypes } from '@/enums'
import UserProfileIcon from '@/components/UserProfileIcon/UserProfileIcon'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'JcniView',
  components: {
    AppDetailHeader,
    BulmaTable,
    JcniMenuBar,
    UserProfileIcon,
    NoteModal
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  data() {
    return {
      selectedJcni: 0,
      jcniObj: {
        0: 'Jobs Completed Not Invoiced',
        1: 'Jobs Overdue To Be Invoiced',
        2: 'Jobs Past Due Date'
      },
      reportName: {
        0: 'rptJCNI',
        1: 'rptJobsOverdueToBeInvoiced',
        2: 'rptJobsPastDueDateNotInvoiced'
      },
      isLoading: false,
      jcniRecords: [],
      selectedRow: null,
      sortColumn: Columns[6].name,
      sortOrder: Columns[6].defaultOrder,
      filter: {
        reportType: 0,
        selectedRow: null,
        sortColumn: Columns[6].name,
        sortOrder: Columns[6].defaultOrder
      },
      isNoteModalActive: false,
      notesContent: '',
      showNoteContent: false,
      notes: [],
      maxNotesShow: 3,
      remarkType: RemarkTypes.JcniNote,
      remarkDesc: 'JCNI Note'
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    includeGst() {
      return this.$company?.info?.isGst
    },
    columns() {
      let clonedColumns = cloneDeep(Columns)
      if (this.includeGst) {
        clonedColumns[8].title = 'Total Inc GST'
      }
      return clonedColumns
    },
    sortedData() {
      const data = cloneDeep(this.jcniRecords)
      return data.sort((a, b) => {
        const modifier = this.sortOrder === 'desc' ? -1 : 1
        if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier
        if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier
        return 0
      })
    }
  },
  watch: {
    selectedJcni() {
      this.getData()
      document.title = this.jcniObj[this.selectedJcni]
    },
    $route: function () {
      if (this.$route.query.jcni !== this.selectedJcni && !!this.$route.query.jcni) {
        this.selectedJcni = parseInt(this.$route.query.jcni)
      } else if (!this.$route.query.jcni) {
        this.selectedJcni = 0
      }
    }
  },
  created() {
    this.retrieveFilter()
  },
  mounted() {
    if (!!this.$route.query.jcni) {
      this.selectedJcni = parseInt(this.$route.query.jcni)
    }
    document.title = this.jcniObj[this.selectedJcni]
    this.getData()
  },
  methods: {
    handleJCNIEvent(data) {
      this.selectedJcni = data
      this.filter.reportType = this.selectedJcni
      this.selectedRow = null
      this.filter.selectedRow = null
      if (this.$route.query.jcni !== this.selectedJcni) {
        this.$router.push({
          query: { jcni: this.selectedJcni }
        })
      }
      this.persistFilter()
    },
    getData() {
      this.jcniRecords = []
      this.filter.reportType = this.selectedJcni
      if (this.selectedJcni == 0) {
        this.getJobsCompletedNotInvoiced()
      } else if (this.selectedJcni == 1) {
        this.getJobsOverdueToBeInvoiced()
      } else if (this.selectedJcni == 2) {
        this.getJobsPastDueDate()
      }
      this.persistFilter()
    },
    async getJobsCompletedNotInvoiced() {
      this.isLoading = true
      const response = await JcniService.getJobsCompletedNotInvoiced()
      this.jcniRecords = response
      this.isLoading = false
    },
    async getJobsOverdueToBeInvoiced() {
      this.isLoading = true
      const response = await JcniService.getJobsOverdueToBeInvoiced()
      this.jcniRecords = response
      this.isLoading = false
    },
    async getJobsPastDueDate() {
      this.isLoading = true
      const response = await JcniService.getJobsPastDueDate()
      this.jcniRecords = response
      this.isLoading = false
    },
    handleQuoteClick(quoteId) {
      // console.log(this.editStoreQuote)
      this.setQuoteReturnRoute(this.$route)
      this.editStoreQuote(quoteId)
    },
    handleSort(column, order) {
      this.sortColumn = column
      this.sortOrder = order
      this.filter.sortColumn = this.sortColumn
      this.filter.sortOrder = this.sortOrder
      this.persistFilter()
    },
    handlePrint() {
      const params = {
        CompanyID: this.$userInfo.companyId
      }
      const emailer = new Emailer()
      emailer.subject = 'JCNI Report'
      emailer.reportName = 'JCNI Report'
      this.addEmailer(emailer)
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.reportName[this.selectedJcni] },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    highlightSelected(index, event) {
      this.selectedRow = index
      this.filter.selectedRow = index
      this.persistFilter()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
        this.selectedRow = this.filter.selectedRow
        this.sortColumn = this.filter.sortColumn
        this.sortOrder = this.filter.sortOrder
      }
    },
    resetNotesContent() {
      this.showNoteContent = false
      this.notesContent = ''
    },
    getNotesContent(isNoteExists, notes) {
      if (isNoteExists) {
        this.notes = _orderBy(notes, 'createdDate', 'desc')
        this.notes = this.notes.splice(0, this.maxNotesShow)
        this.$nextTick(() => {
          this.notesContent = this.$refs['notesList'].innerHTML
        })
        this.showNoteContent = true
      } else {
        this.resetNotesContent()
      }
    },
    notesClick(quoteId, jobNo) {
      this.selectedQuoteId = quoteId
      this.selectedJobNo = jobNo
      this.isNoteModalActive = true
    },
    closeNoteModal(entity) {
      if (entity.length !== 0) {
        const index = this.jcniRecords.findIndex((i) => i.quoteId === this.selectedQuoteId)
        if (index !== -1) {
          this.jcniRecords[index].isNoteExists = true
          this.jcniRecords[index].notes = entity
        }
      }
      this.isNoteModalActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
</style>