import FqApi from '@/services/fq-api'

export default {
  async getEntity(id) {
    const url = '/companysettings/getcompanysetting'
    const result = await FqApi.get(url)
    return result.data
  },
  async setItemAutoCompleteEnabled(value) {
    const enabled = !!value
    const url = `/companysettings/itemautocompleteenabled/${enabled}`
    return await FqApi.put(url)
  },
  async UpdateFiscalSetting(fiscalStart, fiscalDuration) {
    const url = `/companysettings/updatefiscalsetting?fiscalstart=${fiscalStart}&fiscalduration=${fiscalDuration}`
    return await FqApi.put(url)
  },
  async alterCompanySettings(settingName, enable = true) {
    const url = `/companysettings/alterCompanySettings?name=${settingName}&enable=${enable}`
    const result = await FqApi.post(url)
    return result.data
  },
  async getCompanyExternalSettings() {
    const url = '/companysettings/getCompanyExternalSetting'
    const result = await FqApi.get(url)
    return result.data
  }
}
