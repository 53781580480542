import CompanySettingRoutes from './route-types'

const CompanySettingView = () => import(/* webpackChunkName: "company-setting-onboarding-chunk" */ './CompanySettingView')
const CompanyMonthlySetting = () => import(/* webpackChunkName: "company-setting-onboarding-chunk" */ './CompanyMonthlySetting')

export default [
  {
    path: CompanySettingRoutes.CompanySettingView.path,
    name: CompanySettingRoutes.CompanySettingView.name,
    component: CompanySettingView,
    meta: {
      id: 'A190',
      route: 'detail',
      title: 'Company Setting',
      name: 'Company Setting'
    },
    children: [
      {
        path: CompanySettingRoutes.CompanyMonthlySetting.path,
        name: CompanySettingRoutes.CompanyMonthlySetting.name,
        component: CompanyMonthlySetting,
        meta: {
          id: 'A190',
          title: 'Monthly Setting',
          name: 'Monthly Setting'
        }
      }
    ]
  }
]
